import React, { useMemo } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { formatNumber } from "../utils/validationHelpers"

const Plan = ({ plan, tab, isAnnual, userCount, className = "" }) => {
  const {
    name,
    priceA,
    priceM,
    CRMIntegration,
    fiveDaysWeek,
    contactNumber,
    videoMeeting,
    sevenDaysWeek,
    phoneSupport,
    pricePerContactA,
    pricePerContactM,
    discountPerContactA,
    discountPerContactM,
    saveIndividual,
    saveTeamA,
    saveTeamM,
    saveTeamDiscountA,
    saveTeamDiscountM,
    individualM,
    discountM,
    discountA,
  } = plan

  const icon = {
    core: <StaticImage src={"../images/coreIcon.svg"} alt="" />,
    professional: <StaticImage src={"../images/professionalIcon.svg"} alt="" />,
    executive: <StaticImage src={"../images/executiveIcon.svg"} alt="" />,
  }
  const pricePerContact = useMemo(() => {
    if (tab === "INDIVIDUAL" || (tab === "TEAM" && userCount <= 5)) {
      if (isAnnual) {
        return pricePerContactA
      }
      return pricePerContactM
    }
    if (userCount > 5) {
      if (isAnnual) {
        return discountPerContactA
      }
      return discountPerContactM
    }
  }, [isAnnual, userCount])

  const saveMoney = useMemo(() => {
    if (tab === "INDIVIDUAL") {
      return saveIndividual
    }
    if (userCount > 5) {
      if (isAnnual) {
        return saveTeamDiscountA
      }
      return saveTeamDiscountM
    }
    if (isAnnual) {
      return saveTeamA
    }
    return saveTeamM
  }, [isAnnual, userCount])

  const teamPrice = useMemo(() => {
    if (tab === "TEAM") {
      if (userCount > 5) {
        if (isAnnual) {
          return discountA
        }
        return discountM
      }
      if (isAnnual) {
        return priceA
      }
      return priceM
    }
  }, [isAnnual, userCount])

  return (
    <div
      className={`border border-separator rounded-12 text-center px-2 pt-3 pb-4 hover-elevation-1 mt-3 position-relative price__item ${className}`}
    >
      {((tab === "INDIVIDUAL" && isAnnual) || tab === "TEAM") && (
        <div className="save-block position-absolute">
          <StaticImage
            src="../images/triangle.svg"
            alt="SimpleSell"
            className="position-absolute w-100 image-triangle"
          />
          <p className="save-money text-white text-bold position-absolute w-100 text-left">
            Save ${formatNumber(+saveMoney)}
          </p>
        </div>
      )}
      {icon[plan.name]}
      <h2 className="price__title text-bold">{name}</h2>
      <p className="text-rose price__period text-regular">
        Per month / Per user
      </p>
      <div className="text-bold text-brand price__amount">
        {tab === "TEAM" ? (
          <div className="d-flex justify-content-center mb-2">
            <p>
              <span className="d-block text-left text-semibold price__title-team text-secondary">
                Individual / monthly
              </span>
              <span className="d-block mr-2 text-regular text-through text-left text-through__rose">
                ${formatNumber(individualM)}
              </span>
            </p>
            <p>
              <span className="d-block text-left text-semibold price__title-team text-secondary">
                Team
              </span>
              <span className="d-block text-bolder">
                ${formatNumber(+teamPrice)}
              </span>
            </p>
          </div>
        ) : isAnnual ? (
          `$${formatNumber(priceA)}`
        ) : (
          `$${formatNumber(priceM)}`
        )}
      </div>

      {tab === "TEAM" && (
        <div className="text-bold border__dashed">
          {userCount && <p className="mt-2">Total for {userCount} users</p>}
          <p className="price__price text-brand">
            ${formatNumber(teamPrice * userCount)}
          </p>
        </div>
      )}

      <p className="text-brand price__bilyng text-bolder">
        {isAnnual === true ? "Billed Annually" : "Billed Monthly"}
      </p>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://app.simplesell.com/signup"
        className={`selected__plan text-highlighted ${
          userCount < 2 ? "disabled" : ""
        }`}
      >
        {tab === "TEAM" ? 'Subscribe' : 'Try For Free'}
      </a>

      <ul className="second__list text-left">
        {CRMIntegration && (
          <li className="second__item text-regular d-flex justify-content-between">
            <p className="text-brand pr-xs text-m w-75">
              CRM Integration (e.g., Salesforce)
            </p>
            <div className="text-success text-nowrap w-25">
              {<StaticImage src="../images/successIcon.svg" alt="SimpleSell" />}
            </div>
          </li>
        )}
        {fiveDaysWeek && (
          <li className="second__item text-regular d-flex justify-content-between">
            <p className="text-brand pr-xs text-m w-75">
              5 days a week / 9 a.m. - 5 p.m. (MT)
            </p>
            <div className="text-success text-nowrap w-25">
              <StaticImage src="../images/successIcon.svg" alt="SimpleSell" />
            </div>
          </li>
        )}
        {sevenDaysWeek && (
          <li className="second__item text-regular d-flex justify-content-between">
            <p className="text-brand pr-xs text-m w-75">
              5 days a week / 9 a.m. - 5 p.m. (MT)
            </p>
            <div className="text-success text-nowrap w-25">
              <StaticImage src="../images/successIcon.svg" alt="SimpleSell" />
            </div>
          </li>
        )}

        <li className="second__item text-regular d-flex justify-content-between">
          <p className="text-brand pr-xs text-m w-75">
            Contact / Company Profiles
          </p>
          <div className="text-success text-nowrap w-25">
            {contactNumber} / user
          </div>
        </li>
        <li className="second__item text-regular d-flex justify-content-between">
          <p className="text-brand pr-xs text-m w-75">Price per contact</p>
          <div className="text-success text-nowrap w-25">
            ${pricePerContact}
          </div>
        </li>
        <li className="second__item text-regular d-flex justify-content-between">
          <p className="text-brand pr-xs text-m w-75">
            Live agent standup (video meeting)
          </p>
          <div className="text-success text-nowrap w-25">{videoMeeting}</div>
        </li>
        {phoneSupport && (
          <li className="second__item text-regular d-flex justify-content-between">
            <p className="text-brand pr-xs text-m w-75">
              Live agent standup (video meeting)
            </p>
            <div className="text-success text-nowrap w-25">
              <StaticImage src="../images/successIcon.svg" alt="SimpleSell" />
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Plan
